import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Orson Welles’s “The Heart of Darkness”`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`During the summer of 1939, with a world war raging in Europe, the twenty-four year old Orson Welles arrived in Hollywood still fresh from the notoriety of the infamous War of the Worlds radio broadcast. Despite never having made a feature film before, Welles’s contract gave him a degree of control—final cut—that was unprecedented during the studio era. The film Welles ended up making for RKO Pictures, Citizen Kane, is considered by many to be the greatest of all time. But, if either Welles or the studio had gotten their way, Citizen Kane would never have been made.`}</MDXTag>
<MDXTag name="p" components={components}>{`RKO wanted Welles to make a film adaptation of his infamous 1938 War of the Worlds radio broadcast, and so did the general public—in overwhelming numbers, according to studio polls. But, the politically leftist Welles wanted to make an adaptation of Joseph Conrad’s novella Heart of Darkness—a literary exploration of race and colonialism set along a treacherous African river voyage—which functioned as an allegory of the growing threat of fascism. Welles also wanted to shoot the film almost entirely in the first-person singular, with viewers adopting the point of view of the main character. RKO reluctantly agreed, believing it could make an engaging adventure story, and the film went into pre-production. Welles spent much of his first six months in Hollywood between July and December of 1939 hard at work on this project.`}</MDXTag>
<MDXTag name="p" components={components}>{`During this time, Welles engaged several private research assistants to prepare an extensive “research bible” detailing the current state of fascism and wrote a script of “The Heart of Darkness” which he subsequently revised several times. RKO’s location scouts, set designers, and special effects personnel also went to work with the hope of shooting the film entirely on soundstages and on the studio back-lot to keep production costs under the stipulated half-million-dollar budget.`}</MDXTag>
<MDXTag name="p" components={components}>{`Despite all of this work, “The Heart of Darkness” was never made. The sets and other visual components needed to realize Welles’s anti-fascist message made the film’s estimated budget too expensive for RKO, which was already reluctant to produce a film with explicitly political aims. It has since become one of the most famous films never made and continues to be better-known and more written-about than many films that were made in 1939.`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"About Orson Welles's \"The Heart of Darkness\"","key":"about-orson-welles-the-heart-of-darkness","parentKey":"heart-of-darkness","sortOrder":-3};

  